import gtlogo from './codegt-logo.svg';
import aiicon from './aiicon-2.svg';
import './App.css';

function App() {
  return (
    <div className="App h-screen">
      <div class="header-badge bg-gradient-to-r from-darkviolet-700 from-10% via-darkviolet-600 via-30% via-darkviolet-500 via-50% to-darkviolet-800 to-90% border-solid border-b border-blue-600">
        <div class="text-slate-50 dark: text-sm font-mono uppercase py-3">
          Code & AI solutions
        </div>
      </div>
      <header id="main-header" class="w-full bg-gradient-to-r from-darkviolet-950 via-darkviolet-900 to-darkviolet-950 border-solid border-b border-blue-600">
        <div class="mx-auto max-w-[1440px] container ">
          <nav class="flex items-center justify-between flex-1">
            <div><a aria-label="Home" href="/"><img src={gtlogo} className="App-logo my-5 mx-7" alt="logo" /></a></div>
            {/* <div><a aria-label="Home" href="/"><img src={gtlogo} className="App-logo p-8" alt="logo" /></a></div> */}
            {/* <div class="md:hidden">
              <div class="flex items-center"><button class="w-16 h-16 -mr-6 rounded-xl relative"><span class="sr-only">toggle menu</span></button></div></div><div class="hidden w-fit md:flex flex-nowrap absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 space-x-[20px] xl:space-x-[30px]"></div> */}
          </nav>
        </div>
      </header>
      <main class="relative z-10 secc ">        
        <section class="relative max-w-[1080px] mx-auto">
          {/* <div class="secc h-full max-h-[1201px] w-[1501px] absolute z-0 top-0 -left-[180px]">
            <img src={aiicon} className="Ai-logo" alt="logo" />
            <code className="code2">Maintenance, we will be back soon...</code>
          </div> */}
          <div class="flex items-center justify-center min-h-screen">
            <div class="w-full ">
              <div class="relative z-10 mx-auto max-w-[1210px] container">
                <div class="justify-center flex items-center">
                  <img src={aiicon} className="Ai-logo -mt-56" alt="logo" />
                </div>
                <div class="justify-center flex items-center mt-4">
                  <code className="code2 text-slate-50">Maintenance, we will be back soon...</code>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* <header className="App-header hidden">
        <img src={aiicon} className="Ai-logo" alt="logo" />
        <p class="mt-2">
          <code className="code2">Maintenance, we will be back soon...</code>
        </p>
      </header> */}
    </div>
  );
}

export default App;



// --gradient-persian-to-violet: linear-gradient(var(--g1-degrees,90deg),#2f3cc9 var(--g1-start,0%),#ad52dd var(--g1-stop,100%));
// --gradient-persian-to-violet-rev: linear-gradient(var(--g2-degrees,90deg),#ad52dd var(--g2-start,0%),#2f3cc9 var(--g2-stop,100%));
// --gradient-cyan-to-cobalt: linear-gradient(var(--g2-degrees,90deg),#a8d9cc var(--g2-start,0%),#29328d var(--g2-stop,100%));
// --gradient-cyan-to-cobalt-rev: linear-gradient(var(--g2-degrees,90deg),#29328d var(--g2-start,0%),#a8d9cc var(--g2-stop,100%));
// --gradient-orange-to-violet: linear-gradient(var(--g3-degrees,90deg),#ff6b3b var(--g3-start,0%),#ad52dd var(--g3-stop,100%));
// --gradient-orange-to-violet-rev: linear-gradient(var(--g3-degrees,90deg),#ad52dd var(--g3-start,0%),#ff6b3b var(--g3-stop,100%));
// --gradient-pink-to-violet: linear-gradient(var(--g3-degrees,90deg),#e54692 var(--g3-start,0%),#ad52dd var(--g3-stop,100%));
// }


// <div class="bg-gradient-to-r from-indigo-500 from-10% via-sky-500 via-30% to-emerald-500 to-90% ..."></div>